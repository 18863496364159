import React, { ReactElement } from 'react';
import { NextPageContext } from 'next';
import Error from 'next/error';
import * as Sentry from '@sentry/node';
import withLocale from 'hocs/withLocale';
import useLocale from 'hooks/useLocale';
import Layout from 'components/layout';
import Error0 from '../public/images/errors/0.svg';
import Error400 from '../public/images/errors/400.svg';
import Error404 from '../public/images/errors/404.svg';
import Error405 from '../public/images/errors/405.svg';
import Error500 from '../public/images/errors/500.svg';
import {
  capitalize,
} from '../core/utils';

const statusCodes: { [code: number]: string } = {
  400: 'bad request',
  404: 'this page could not be found',
  405: 'method not allowed',
  410: 'gone',
  500: 'internal server error',
};

const className = 'h-auto max-w-xs mx-auto sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"';

const illustrations: { [code: number]: ReactElement } = {
  0: <Error0 className={className} />,
  400: <Error400 className={className} />,
  404: <Error404 className={className} />,
  405: <Error405 className={className} />,
  500: <Error500 className={className} />,
};

export type ErrorProps = {
  statusCode: number;
  hasGetInitialPropsRun?: boolean;
  err?;
}

const MyError = ({
  statusCode, hasGetInitialPropsRun, err,
}): ReactElement => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/zeit/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
  }

  const { t } = useLocale();
  const title = capitalize(t(statusCodes[statusCode] || 'an unexpected error has occurred'));

  return (
    <Layout title={title}>
      <div className="my-16">
        {illustrations[statusCode] || illustrations[0]}
        <div className="my-3 text-2xl font-bold leading-snug tracking-tight text-center text-gray-900 dark:text-dark-top-medium">
          {title}
        </div>
      </div>
    </Layout>
  );
};

MyError.getInitialProps = async ({ res, err, asPath }: NextPageContext): Promise<ErrorProps> => {
  const errorInitialProps = await Error.getInitialProps(
    { res, err } as NextPageContext,
  ) as ErrorProps;

  // Workaround for https://github.com/zeit/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true;

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (res?.statusCode === 404 || res?.statusCode === 410) {
    // Opinionated: do not record an exception in Sentry for 404 or 410
    return { statusCode: res.statusCode };
  }

  if (err) {
    Sentry.captureException(err);
    return errorInitialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(
    `_error.tsx getInitialProps missing data at path: ${asPath}`,
  );

  return errorInitialProps;
};

export default withLocale(MyError);
